import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import App from './App';

const root = document.getElementById('root');
if(root.hasChildNodes()){
  ReactDOM.hydrateRoot(root).render(<App />)
}else{
  ReactDOM.createRoot(root).render(<App />)
}