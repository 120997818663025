import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import $ from "jquery"

import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"

import { useTranslate } from '../../contexts/translate';

import projects from "../../assets/projects.json"

import "./style.css"
import "./mobile.css"
import "./tablet.css"

const Projects = () => {

    const [language] = useTranslate()

    const navigate = useNavigate()

    const listProjects = projects.map((project, p) => {
        let types = ""
        const typesArr = language.id == 'pt' ? project.types.pt : project.types.en
        typesArr.forEach((type, t) => {
            if(t == 0){
                types = type
            }else{
                types = types + " - " + type
            }
        })
        

        return (
            <article className="project" onClick={(e) => navigate(`/projects/${project.id}`)}>
                <div className="images">
                    <div className="top"><img src={require(`../../assets/projects/${project.preview.top}`)} alt="" /></div>
                    <div className="bottom"><img src={require(`../../assets/projects/${project.preview.bottom}`)} alt="" /></div>
                </div>
                <div className="data">
                    <div className="name">{language.id == 'pt' ? project.data.pt.name : project.data.en.name}</div>
                    <div className="type">{types}</div>
                </div>
            </article>
        )
    })

	const animateLoad = () => {
		$("main").removeClass("out")
	}

	useEffect(() => {
		$("html, body").stop().animate({scrollTop:0}, 500, 'swing', function() { 
			setTimeout(() => {
				animateLoad()
			}, 500);
		})
	})

    return (
        <main className="projects out">
            <Helmet>
                <title>Katsu! Agency - Projects</title>
            </Helmet>
            <Navbar></Navbar>
            <div className="page">
                <div className="title">{language.navbar.projects}</div>
                <div className="list">
                    {listProjects}
                </div>
            </div>
            <Footer></Footer>
        </main>
    );
}
 
export default Projects;