import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import $ from "jquery"
import emailjs from '@emailjs/browser';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

import { useTranslate } from "../../contexts/translate"

import BeKatsuProjects from '../../assets/images/BeKatsuProjects.svg'
import HeroMp4 from '../../assets/videos/hero-bg.webm'
import partners from "../../assets/partners.json"

import "./style.css"
import "./tablet.css"
import "./mobile.css"

const animateToBe = () => {
	let $tobe = $(".to-be"),
		$scroll = $(".to-be .scroll"),
		isSecond = $scroll.hasClass("animSecond"),
		isThird = $scroll.hasClass("animThird"),
		isBackwards = $scroll.hasClass("animSecondBack"),
		isFirst = !isSecond && !isThird && !isBackwards,

		firstHeight = $scroll.find("div:nth-child(1) span").height(),
		secondHeight = $scroll.find("div:nth-child(2) span").height(),
		thirdHeight = $scroll.find("div:nth-child(3) span").height()

	setTimeout(() => {
		if (!$scroll.hasClass("gradient")) $scroll.addClass("gradient")
		
		if (isFirst) {
			$scroll.addClass("animSecond")
			$tobe.height(secondHeight)
		} else if (isSecond) {
			$scroll.addClass("animThird").removeClass("animSecond")
			$tobe.height(thirdHeight)
		} else if (isThird) {
			$scroll.addClass("animSecondBack").removeClass("animThird")
			$tobe.height(secondHeight)
		} else if (isBackwards) {
			$scroll.removeClass("animSecondBack")
			$tobe.height(firstHeight)
		}
	}, 500);

	setTimeout(() => {
		animateToBe()
	}, 2000);
}

animateToBe()

const Home = () => {

    const navigate = useNavigate()
	const [language] = useTranslate()

    const handleClickSendEmail = () => {
        const form = document.getElementById("contact").getElementsByTagName("form")[0]
        emailjs.sendForm('gmailMessage', 'template_28sx95v', form, 'zMLhZ4J9RqaYfljxd')
        .then((result) => {
            alert(language.home.email)
        }, (error) => {
            alert("Error.")
        });
    }

	const animateLoad = () => {
		$("main, .hero").removeClass("out")
	}

	const animateOnScroll = () => {
		$(".bekatsu").removeClass("out")
	}

    const listPartners = partners.map((partner, p) => {
        return (
            <div className="partner" key={p}>
                <img src={require(`../../assets/images/partners/${partner}.png`)} alt={`Parceiro ${partner}`} />
            </div>
        )
    })

	useEffect(() => {
		$(".to-be").height($(".to-be .scroll div:first-child span").height())
		$(".hero video").on("loadeddata", () => {
			$("html, body").stop().animate({scrollTop:0}, 500, 'swing', function() { 
				animateLoad()
				$(window).on("scroll", function(){
					let scrollPos = $(window).scrollTop(),
						scrollContent = $(".bekatsu .content").offset().top - 700
					if (scrollPos >= scrollContent) {

						animateOnScroll()

						$(window).off("scroll")

					}
				})
			})
		})
	}, [])

    return (
        <main className="home out">
            <Helmet>
                <title>Katsu! Agency - Home</title>
            </Helmet>
            <Navbar></Navbar>
            <section className="hero out">
				<div className="lettering">
					<div className="row">
						<span className="minor">{language.home.hero.minor.top.male}</span>
						<span className="major">{language.home.hero.major.chaos}</span>
					</div>
					<div className="row">
						<span className="minor">{language.home.hero.minor.bottom.female}</span>
						<span className="major">{language.home.hero.major.creation}</span>
					</div>
				</div>
				<video loop autoPlay muted={true} playsInline>
                	<source src={HeroMp4} type="video/mp4" />
				</video>
            </section>
            <section className="bekatsu out" id='bekatsu'>
                <div className="content">
                    <div className="left">
                        <div className="texts">
                            <div className="title">
								<div className="to-be">
									<div className="scroll">
										<div><span>{language.home.bekatsu.toBe.three}</span></div>
										<div><span>{language.home.bekatsu.toBe.two}</span></div>
										<div><span>{language.home.bekatsu.toBe.one}</span></div>
									</div>
								</div>
								<span className='white'>KATSU!</span>
								</div>
                            <div className="description">{language.home.bekatsu.description}</div>
                        </div>
                        <div className="parallax">
                            <img src={BeKatsuProjects} alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="types">
                            <div className="type">BRANDING</div>
                            <div className="type">DESIGN</div>
                            <div className="type">MOTION</div>
                            <div className="type">BROADCAST</div>
                            <div className="type">AUDIOVISUAL</div>
							<div className="type">GRAFISMO</div>
                            <div className="type">WEB DEV</div>
                        </div>
                        <Button click={(e) => navigate(`/projects`)} justify="flex-end">{language.home.ourprojects}</Button>
                    </div>
                </div>
            </section>
            <section className="partners" id='partners'>
                <div className="content">
                    <div className="title">{language.home.partners}</div>
                    <div className="list">
                        {listPartners}
                    </div>
                </div>
            </section>
            <section className="contact" id='contact'>
                <form className="content">
                    <div className="title">{language.home.contact}</div>
                    <div className="texts">
                        <input type="text" placeholder={language.home.placeholders.name} name="name" required/>
                        <input type="email" placeholder={language.home.placeholders.email} name="email" required/>
                        <textarea placeholder={language.home.placeholders.message} name="message" cols="30" rows="10" required></textarea>
                    </div>
                    <Button click={(e) => handleClickSendEmail()} justify="center">{language.home.send}</Button>
                </form>
            </section>
            <Footer></Footer>
        </main>
    );
}
 
export default Home;
