import React from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/Home'
import Projects from './pages/Projects'
import Project from './pages/Project'

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/projects" exact element={<Projects />} />
                <Route path="/projects/:id" exact element={<Project />} />
                <Route path='*' element={<Navigate to="/"></Navigate>} />
            </Routes>
        </Router>
    )
}
 
export default AppRoutes;