import React from 'react';

import Behance from "../../assets/images/Behance.png"
import Twitter from "../../assets/images/Twitter.png"
import Instagram from "../../assets/images/Instagram.png"
import LogoSimple from "../../assets/images/LogoSimple.png"

import "./style.css"

const Footer = () => {
    return (
        <footer className="footer">
			<div className="wrap">
				<div className="socialmedias">
					<div>
						<a target="_blank" aria-label="Behance" href="https://www.behance.net/katsuagency"><img src={Behance} alt="" /></a>
						<a target="_blank" aria-label="Twitter" href="https://www.twitter.com/katsuagency"><img src={Twitter} alt="" /></a>
						<a target="_blank" aria-label="Instagram" href="https://www.instagram.com/katsuagency"><img src={Instagram} alt="" /></a>
					</div>
				</div>
				<div className="copyright">
					<a href="mailto:contato@katsuagency.com" aria-label="Email to Katsu! Agency" className="email">contato@katsuagency.com</a>
					<div className="namingrights">© 2023 Katsu! Agency</div>
				</div>
				<div className="logo">
					<img src={LogoSimple} alt="" />
				</div>
			</div>
        </footer>
    );
}
 
export default Footer;