import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import $ from "jquery"

import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"

import projects from "../../assets/projects.json"

import "./style.css"
import "./mobile.css"
import "./tablet.css"

const Project = () => {

    const { id } = useParams()
    const navigate = useNavigate()

    const project = projects.filter((project) => project.id == id)[0]
    const portuguese = localStorage.getItem("language") == undefined || localStorage.getItem("language") == null || localStorage.getItem("language") == "pt" ? true : false

    useEffect(() => {
        if(projects.filter((project) => project.id == id).length <= 0){
            navigate(`/projects`)
        }
    })

    const proxSlide = () => {
        const size = $(".slideshow .content .slide").length
        if(size > 4){
            const last = size - 1
            if(!$(".slideshow .content .slide").eq(last).hasClass("show")){
                let indexes = []
                $(".slideshow .content .slide").each((s, slide) => {
                    if($(slide).hasClass("show")){
                        indexes.push(s)
                    }
                })
                $(".slideshow .content .slide").eq(indexes[0]).removeClass("show")
                const nextIndex = indexes[3] + 1
                $(".slideshow .content .slide").eq(nextIndex).addClass("show")
            }
        }
    }

    const antSlide = () => {
        const size = $(".slideshow .content .slide").length
        if(size > 4){
            if(!$(".slideshow .content .slide").eq(0).hasClass("show")){
                let indexes = []
                $(".slideshow .content .slide").each((s, slide) => {
                    if($(slide).hasClass("show")){
                        indexes.push(s)
                    }
                })
                const antIndex = indexes[0] - 1
                $(".slideshow .content .slide").eq(antIndex).addClass("show")
                $(".slideshow .content .slide").eq(indexes[3]).removeClass("show")
            }
        }
    }

    const typesPt = project.types.pt.map((type) => {
        return (
            <div className="type">{type}</div>
        )
    })

    const typesEn = project.types.en.map((type) => {
        return (
            <div className="type">{type}</div>
        )
    })

    const slides = project.body.images.map((image, m) => {
        const classSlide = m < 4 ? "slide show" : "slide"
        if(image.includes(".mp4") || image.includes(".wmv") || image.includes(".mov") || image.includes(".avi") || image.includes(".m4v") || image.includes(".mpg") || image.includes(".mpeg")){
            const typeVideo = "video/" + image.split(".")[1]

            return (
                <div className={classSlide}>
                    <video autoplay muted loop>
                        <source src={require("../../assets/projects/" + image)} type={typeVideo} />
                    </video>
                </div>
            )
        }else{
            return (
                <div className={classSlide}><img src={require("../../assets/projects/" + image)}/></div>
            )
        }
    })

	const animateLoad = () => {
		$("main").removeClass("out")
	}

	useEffect(() => {
		if ($(".video video").length != 0) {
			$(".video video").on("loadeddata", () => {
				$("html, body").stop().animate({scrollTop:0}, 500, 'swing', function() { 
					setTimeout(() => {
						animateLoad()
					}, 500);
				})
			})
		}
	})

    return (
        <main className="projectunique out">
            <Helmet>
                <title>Katsu! Agency - Projects</title>
            </Helmet>
            <Navbar></Navbar>
            <div className="video">
				{!project.body.video.includes(".mp4") &&
                	<img src={require(`../../assets/projects/${project.body.video}`)} alt="" />
				}
				{project.body.video.includes(".mp4") &&
                	<video autoPlay muted={true} loop playsInline>
						<source src={require("../../assets/projects/" + project.body.video)} type="video/mp4" />
					</video>
				}
            </div>
            <div className="data">
				<div className="title">{portuguese ? project.data.pt.name : project.data.en.name}</div>
                <div className="left">
                    <div className="description">{portuguese ? project.data.pt.description : project.data.en.description}</div>
                </div>
                <div className="right">
                {portuguese ? typesPt : typesEn}
                </div>
            </div>
            <div className="slideshow">
                <div className="cursors">
                    <div className="left" onClick={(e) => antSlide()}></div>
                    <div className="right" onClick={(e) => proxSlide()}></div>
                </div>
                <div className="content">
                    {slides}
                </div>
            </div>
            <Footer></Footer>
        </main>
    );
}
 
export default Project;