import React from 'react';
import AppRoutes from './AppRoutes';
import { TranslateProvider } from './contexts/translate';

const App = () => {
  return (
    <TranslateProvider>
      <AppRoutes></AppRoutes>
    </TranslateProvider>
  )
}
 
export default App;