import { useContext, createContext, useMemo } from "react";
import useLocalStorage from "react-use-localstorage";

export const TranslateContext = createContext({
    language: null,
    setLanguage: () => null
})

export const TranslateProvider = (props) => {

    const [language,setLanguage] = useLocalStorage('language', 'pt')
    const lang = useMemo(() => require(`../assets/languages/${language}.json`), [language])

    return (
        <TranslateContext.Provider
        value={[lang,setLanguage]}
        >
            {props.children}
        </TranslateContext.Provider>
    )
}

export const useTranslate = () => useContext(TranslateContext)