import React from 'react';

import Arrow from '../../assets/images/Arrow.png'

import "./style.css"

const Button = (props) => {
    return (
        <div className="btn-container" style={{justifyContent: props.justify}}>    
            <div className="btn" onClick={props.click}>
                {props.children}
                <div className="arrow">
                    <img src={Arrow} alt="" />
                </div>
            </div>
        </div>
    )
}
 
export default Button;