import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from "jquery"

import { useTranslate } from "../../contexts/translate"

import Logo from '../../assets/images/Logo.png'
import LogoSimple from '../../assets/images/LogoSimple.png'
import IconOpenNavbar from "../../assets/images/IconOpenNavbar.png"
import IconCloseNavbar from "../../assets/images/IconCloseNavbar.png"

import './style.css'

const Navbar = () => {

    const [language,setLanguage] = useTranslate()
    const navigate = useNavigate()

	const animateLoad = () => {
		$(".navbar").removeClass("out")
	}

    useEffect(() => {
		animateLoad()
    }, [])

    const showMobileNavbar = () => {
        $(".mobilemenu").addClass("show")
        $(".mobilelanguage").addClass("show")
        $(".mobileoptions .show").addClass("hide")
        $(".mobileoptions .close").removeClass("hide")
    }

    const hideMobileNavbar = () => {
        $(".mobilemenu").removeClass("show")
        $(".mobilelanguage").removeClass("show")
        $(".mobileoptions .show").removeClass("hide")
        $(".mobileoptions .close").addClass("hide")
    }

    const navigateMobile = (link) => {
        navigate(link)
        $(".mobilemenu").removeClass("show")
        $(".mobileoptions .show").removeClass("hide")
        $(".mobileoptions .close").addClass("hide")
    }

    return (
		<header className="navbar out">
			<div className="wrap">
				<a className='logo' aria-label="Katsu! Agency" href="#" onClick={(e) => navigate(`/`)}><img src={Logo} alt="" /></a>
				<a className='mobilelogo' aria-label="Katsu! Agency" href="#" onClick={(e) => navigate(`/`)}><img src={LogoSimple} alt="" /></a>
				<div className="options">
					<nav className="navigation">
						<a href="#bekatsu" aria-label="About" onClick={(e) => navigate(`/`)}>{language.navbar.about}</a>
						<a href="#" aria-label="Projects" onClick={(e) => navigate(`/projects`)}>{language.navbar.projects}</a>
						<a href="#partners" aria-label="Partners" onClick={(e) => navigate(`/`)}>{language.navbar.partners}</a>
						<a href="#contact" aria-label="Contact" onClick={(e) => navigate(`/`)}>{language.navbar.contact}</a>
					</nav>
					<div className="language">
						<button onClick={(e) => setLanguage("pt")} className={language.id == 'pt' ? 'selected': ''}>PT</button>
						<button onClick={(e) => setLanguage("en")} className={language.id == 'en' ? 'selected': ''}>EN</button>
					</div>
				</div>
				<div className="mobileoptions">
					<a href='#' className='show' aria-label="Show Mobile Navbar" onClick={showMobileNavbar}><img src={IconOpenNavbar} alt="" /></a>
					<a href='#' onClick={hideMobileNavbar} aria-label="Hide Mobile Navbar" className='close hide'><img src={IconCloseNavbar} alt="" /></a>
				</div>
			</div>
			<nav className="mobilemenu">
				<a href="#bekatsu" aria-label="About" onClick={(e) => navigateMobile(`/`)}>{language.navbar.about}</a>
				<a href="#" aria-label="Projects" onClick={(e) => navigateMobile(`/projects`)}>{language.navbar.projects}</a>
				<a href="#partners" aria-label="Partners" onClick={(e) => navigateMobile(`/`)}>{language.navbar.partners}</a>
				<a href="#contact" aria-label="Contact" onClick={(e) => navigateMobile(`/`)}>{language.navbar.contact}</a>
			</nav>
			<div className="mobilelanguage">
				<button onClick={(e) => setLanguage("pt")} className={language.id == 'pt' ? 'selected': ''}>PT</button>
				<button onClick={(e) => setLanguage("en")} className={language.id == 'en' ? 'selected': ''}>EN</button>
			</div>
		</header>
    )
}
 
export default Navbar;